.footer {
  min-height: 180px;
  background-image: radial-gradient(rgb(20, 20, 20), rgb(18, 18, 18));
}

.footer h5 {
  text-align: center;
  padding: 80px;
  margin: 0;
  font-size: 1.64rem;
  line-height: 110%;
}

.footer h5 a {
  color: #039be5;
  text-decoration: none;
  -webkit-tap-highlight-color: transparent;
}
