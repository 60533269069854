@keyframes livePulsing {
  0% {
    transform: scale(1);
    opacity: 0.4;
  }
  20% {
    opacity: 0.4;
  }
  40% {
    transform: scale(1.8);
    opacity: 0;
  }
  100% {
    transform: scale(1);
    opacity: 0;
  }
}

.live-show {
  opacity: 1;
  transform: translateY(0px);
}

.live-hide {
  opacity: 0;
  transform: translateY(-20px);
}

.live-container {
  position: fixed;
  z-index: 33;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  user-select: none;
  text-decoration: none;
  line-height: 1.3;
}

.live-pill {
  background-color: #fff;
  border-radius: 40px;
  color: #212121;
  font-weight: 700;
  padding: 2px 12px;
  letter-spacing: 2px;
  box-shadow: 4px 6px 12px rgba(0, 0, 0, 0.3);
  transition: all 0.2s;
  font-size: 18px;
  line-height: 1.3;
  text-decoration: none;
  cursor: pointer;
}

.live-red-ball-outer {
  display: inline-block;
  height: 12px;
  width: 12px;
  border-radius: 100%;
  background-color: #f44336;
  margin-right: 6px;
  position: relative;
  transition: all 0.2s;
  line-height: 1.3;
}

.live-red-ball-inner {
  height: 100%;
  width: 100%;
  background-color: #f44336;
  border-radius: 100%;
  animation-name: livePulsing;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  transition: all 0.2s;
  line-height: 1.3;
}

.live-pill:hover {
  background-color: #f44336;
  color: #fff;
  line-height: 1.3;
}

.live-pill:hover .live-red-ball-outer {
  background-color: #fff;
  line-height: 1.3;
}

.live-pill:hover .live-red-ball-inner {
  background-color: #fff;
  line-height: 1.3;
}
